import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../assets/image/logo.png";
import ApplyJob from "./ApplyJob";
import axios from "axios";
import Loader from "./loader/Loader";
import { AppContext } from "../context/AppContext";
import { BASE_URI } from "../constant/constant";

const JobDetails = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const [loader, setLoader] = useState(false);
  const { shortName } = useParams();
  const { setData, data } = useContext(AppContext);
  const [isError, setIsError] = useState({ status: false, message: "" });

  const handleToggle = () => {
    setToggleForm((prev) => !prev);
  };

  const fetchCompany = async () => {
    setLoader(true);
    try {
      const request = {
        method: "get",
        url: `${BASE_URI}/api/company/data?shortname=${shortName}`,
        headers: {
          // Authorization: "469917889",
        },
      };

      const { data } = await axios(request);

      const delay = (callback) => {
        return new Promise((resolve) => {
          callback();
          setTimeout(() => {
            resolve("done");
          }, 2000);
        });
      };

      await delay(() => {
        setData(data.data);
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
      setIsError({ status: true, message: error.response.data.error });
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchCompany();
    // Function to clear complete cache data
  }, []);

  return (
    <div className="main-box">
      {loader ? (
        <Loader />
      ) : isError.status ? (
        <h1>Company not found</h1>
      ) : (
        <>
          <div className="jobHeader">
            <div className="maniContainer">
              <div className="dFlex textCenterSm">
                <div className="col8 colSm12">
                  {/* <h1>Cloud Technology Architecture</h1> */}
                  <h1>{data.job_title}</h1>
                  <div className="applyBtn mb2">
                    <Button onClick={handleToggle} variant="contained">
                      Apply Now
                    </Button>
                  </div>
                </div>
                <div className="col4 colSm12">
                  <div className="logo-job ">
                    <img src={data.logo} alt="Logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {toggleForm && <ApplyJob setToggleForm={setToggleForm} />}
          <div className="maniContainer">
            <div className="jobDescription">
              <div className="col8 colSm12">
                <h1 className="mb1">Job Description</h1>
                <div
                  dangerouslySetInnerHTML={{ __html: data.job_description }}
                ></div>
                {/* {data.job_description
                    ? data.job_description
                    : `Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Natus at, placeat quidem sapiente dicta illum? Rem, autem
                    impedit. Quia ullam, consequuntur aperiam necessitatibus
                    sapiente officiis quam quae? Officiis fugiat ipsum odit
                    animi necessitatibus optio facilis maiores, pariatur quaerat
                    explicabo, perferendis velit quam. Tempore deserunt
                    voluptatem aspernatur corrupti repellendus magni iusto?`} */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default JobDetails;
