import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";

const Navbar = () => {
  const { data } = useContext(AppContext);

  return (
    <>
      {data?.name && data.logo ? (
        <div className="navbar">
          <div className="container">
            <div className="dFLex alignItemCenter justify-content-center-sm">
              <div className="logo">
                <img src={data.logo} alt="" />
              </div>
              <div className="companyName">
                <h2>{data?.name}</h2>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Navbar;
