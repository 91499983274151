import "./assets/styles/style.css";
import { Toaster } from "react-hot-toast";
import Navbar from "./components/Navbar";
import JobDetails from "./components/JobDetails";
import { Route, Routes } from "react-router-dom";
import { AppContext } from "./context/AppContext";
import { useEffect, useState } from "react";

function App() {
  const [data, setData] = useState({});

  useEffect(() => {
    const clearCacheData = () => {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // alert("Complete Cache Cleared");
    };
    clearCacheData();
  });

  return (
    <AppContext.Provider value={{ data, setData }}>
      <Toaster />
      <Navbar />
      <Routes>
        <Route
          exact
          path="/"
          element={<h1>Invalid URL: Missing company Shortname</h1>}
        />
        <Route exact path="/:shortName" element={<JobDetails />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
