import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Loader from "./loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../context/AppContext";
import { BASE_URI } from "../constant/constant";

const initialValue = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
};

const ApplyJob = ({ setToggleForm }) => {
  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState({ value: {}, status: false });
  const [formErrorCustom, setFormErrorCustom] = useState({
    value: {},
    status: false,
  });
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    status: true,
  });
  const { data } = useContext(AppContext);

  useEffect(() => {
    const customField = data?.custom_fields?.map(({ index }) => ({
      index,
      value: "",
    }));

    setInputFields(customField);
  }, []);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    if (responseMessage.status) {
      setToggleForm(false);
    }
  };

  const handleValidate = (data) => {
    const error = { value: {}, status: false };

    const emptyString = /^[a-zA-Z]+[a-zA-Z0-9 !@#\$%\^\&*\)\(+=._-]{1,20}$/;
    const emptyNum = /^[0-9]+[0-9 !@#\$%\^\&*\)\(+=._-]{1,15}$/;

    const fieldRegex = {
      firstName: emptyString,
      lastName: emptyString,
      phoneNumber: emptyNum,
    };

    for (let key in data) {
      if (data[key].length > 20) {
        error.value[key] = `${key} enter only 20 characters`;
        error.status = true;
      } else {
        const strCheck = fieldRegex[key].test(data[key]);
        if (!strCheck) {
          error.value[key] = `The ${key} field is required.`;
          error.status = true;
        }
      }
    }
    return error;
  };

  const handleValidateArr = (data) => {
    const error = { value: {}, status: false };

    const emptyString =
      /^[a-zA-Z0-9][^\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?].*$/;
    const emptyNum = /^[0-9]+[0-9 !@#\$%\^\&*\)\(+=._-]{1,50}$/;

    for (let key in data) {
      const strCheck = emptyString.test(data[key].value);
      if (!strCheck) {
        error.value[key] = `This text field is required.`;
        error.status = true;
      }
    }
    return error;
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const checkValidate = handleValidate(formValue);

      const checkValidateCustom = handleValidateArr(inputFields);
      setFormErrorCustom(checkValidateCustom);

      setFormError(checkValidate);

      if (!checkValidate.status && !checkValidateCustom.status) {
        setIsLoading(true);

        // const customField = inputFields.map((item) => {
        //   return {
        //     field_data: {
        //       keyname: item.key,
        //       value: item.value,
        //     },
        //   };
        // });

        const body = {
          first_name: formValue.firstName,
          last_name: formValue.lastName,
          phone_number: formValue.phoneNumber,
          op_job_id: data.op_job_id,
          custom_fields: inputFields,
        };

        const request = {
          method: "post",
          url: `${BASE_URI}/api/applicants`,
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
          data: JSON.stringify(body),
        };

        const res = await axios(request);

        console.log("res", res);
        setFormValue(initialValue);
        setIsLoading(false);
        setResponseMessage({
          status: true,
          message: "Application submitted successfully!",
        });
        handleClickOpen();
        // toast.success("Application save successfully!");
      }
    } catch (error) {
      setIsLoading(false);
      setResponseMessage({
        status: false,
        message: error?.response?.data?.error,
      });
      handleClickOpen();
      console.log("error", error);
    }
  };

  const handleSelectChange = (e, index) => {
    const copy = [...inputFields];
    copy[index].value = e.target.value;
    setInputFields(copy);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ padding: "26px 21px 0 21px" }}>
            <h1
              className={`colorSuccess ${
                !responseMessage.status && "colorRed"
              }`}
            >
              {responseMessage.message}
            </h1>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="py4 maniContainer">
        <div className="mb2">
          <h1 className="mb2">Apply for job</h1>
          <p>
            Please complete the following fields with your personal information.
          </p>
        </div>
        <div className="col6 py3 colSm12">
          <div className="formInput">
            <TextField
              inputProps={{ maxLength: 20 }}
              value={formValue.firstName}
              onChange={handleChange}
              helperText={
                formError.value.firstName && formError.value?.firstName
              }
              error={formError.value?.firstName ? true : false}
              name="firstName"
              id="outlined-basicg"
              fullWidth
              label="First Name"
              variant="outlined"
            />
          </div>
          <div className="formInput">
            <TextField
              inputProps={{ maxLength: 20 }}
              value={formValue.lastName}
              onChange={handleChange}
              helperText={formError.value.lastName && formError.value?.lastName}
              error={formError.value?.lastName ? true : false}
              name="lastName"
              id="outlined-basicf"
              fullWidth
              label="Last Name"
              variant="outlined"
            />
          </div>
          <div className="formInput">
            <TextField
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 15);
              }}
              value={formValue.phoneNumber}
              onChange={handleChange}
              helperText={
                formError.value.phoneNumber && formError.value?.phoneNumber
              }
              error={formError.value?.phoneNumber ? true : false}
              name="phoneNumber"
              type={"number"}
              id="outlined-basics"
              fullWidth
              label="Phone Number"
              variant="outlined"
            />
          </div>
          <div>
            {data?.custom_fields?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Box
                    key={index}
                    sx={{
                      pt: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <li>{item.value}</li>
                  </Box>
                  <div style={{ width: "100%", paddingBottom: "15px" }}>
                    <TextField
                      fullWidth
                      id={`standard-basic-${index}`}
                      variant="standard"
                      label="Answer"
                      size="small"
                      value={inputFields[index]?.value}
                      sx={{ background: "#fbf6f6" }}
                      onChange={(e) => handleSelectChange(e, index)}
                      error={formErrorCustom?.value[index] ? true : false}
                      helperText={
                        formErrorCustom?.value[index] &&
                        formErrorCustom?.value[index]
                      }
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          <div className="formInput">
            <Button
              onClick={handleSubmit}
              variant="contained"
              type="submit"
              fullWidth
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyJob;
